import * as React from "react";
import { graphql } from "gatsby";

import { StoryblokComponent, storyblokEditable, useStoryblokState } from "gatsby-source-storyblok";

// Import global components
import Layout from "../components/globals/Layout";
import { SEO } from "../components/globals/SEO";

// Page layout
const IndexPage = ({ data }) => {
  let story = data.storyblokEntry;
  story = useStoryblokState(story);

  const components = story.content.body.map((blok) => (
    <StoryblokComponent blok={blok} key={blok._uid} />
  ));

  return (
    <>
      <Layout>
        <div {...storyblokEditable(story.content.body)}>{components}</div>
      </Layout>
    </>
  );
};

export default IndexPage;

// Exports SEO tags to the Head of the file
export const Head = ({ data }) => {
  let story = data.storyblokEntry;
  story = useStoryblokState(story);
  return <SEO blok={story.content} />;
};

export const query = graphql`
  query HomeQuery {
    storyblokEntry(full_slug: { eq: "home" }) {
      content
      name
      full_slug
      uuid
      id
      internalId
    }
  }
`;
